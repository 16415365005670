<template>
  <v-alert
    class="mt-2 message-bar"
    v-model="internalValue"
    transition="fade-transition"
    :type="mappedType"
    :dismissible="isErrorMessage"
    @input="onAlertInput"
  >
    {{ currentMessage }}
  </v-alert>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MessageComponent",

  data() {
    return {
      // controls v-alert open/close state
      internalValue: false,
    };
  },

  computed: {
    // Get the entire message object from Vuex store
    ...mapGetters("messages", ["messages"]),

    currentMessage() {
      return this.messages.message;
    },

    // Convert "danger" -> "error" to match Vuetify’s <v-alert> types
    mappedType() {
      const valid = ["info", "success", "warning", "error", "danger"];
      const theClass = this.messages.messageClass || "warning";
      if (!valid.includes(theClass)) {
        return "info";
      }
      return theClass === "danger" ? "error" : theClass;
    },

    isErrorMessage() {
      // If mappedType is "error", show dismiss button
      return this.mappedType === "error";
    }
  },

  watch: {
    /**
     * Whenever the store's message text changes, 
     * we decide if the v-alert should be open or closed.
     */
    currentMessage(newVal) {
      if (newVal && newVal.trim().length > 0) {
        this.internalValue = true;   // show the alert
      } else {
        this.internalValue = false;  // hide the alert
      }
    }
  },

  methods: {
    // We'll call "clearMessage" from store if user dismisses an error
    ...mapActions("messages", ["clearMessage"]),

    onAlertInput(value) {
      // v-alert triggers this with `false` if user clicks the dismiss (X) button
      if (value === false) {
        this.clearMessage();
      }
    }
  }
};
</script>

<style scoped>
.message-bar {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  min-height: 50px;
  z-index: 9999;
  margin-left: -25%;
}
</style>
