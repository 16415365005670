// store/modules/messages.js

const state = {
  messageGroup: {
    messageClass: "",
    message: "",
  },
  timeoutEvent: null,
  timeout: 5000, // default auto-clear in ms for non-error messages
};

const mutations = {
  ADD_MESSAGE(state, { message, messageClass }) {
    // Basic fallback if not provided
    const finalClass = messageClass || "warning";
    const finalText = message || "Oops.. an unknown error occurred";

    // Set new message
    state.messageGroup = {
      messageClass: finalClass,
      message: finalText,
    };

    // Clear any existing timer
    if (state.timeoutEvent) {
      clearTimeout(state.timeoutEvent);
      state.timeoutEvent = null;
    }

    // Only auto-clear if NOT "danger"
    if (finalClass !== "danger") {
      state.timeoutEvent = setTimeout(() => {
        state.messageGroup = {
          messageClass: "",
          message: "",
        };
        state.timeoutEvent = null;
      }, state.timeout);
    }
  },

  CLEAR_MESSAGE(state) {
    // Immediately clear message
    state.messageGroup = {
      messageClass: "",
      message: "",
    };
    if (state.timeoutEvent) {
      clearTimeout(state.timeoutEvent);
      state.timeoutEvent = null;
    }
  },

  SET_TIMEOUT(state, { timeout }) {
    state.timeout = timeout;
  },
};

const actions = {
  // General usage, can be simplified to just one if you like
  addMessage({ commit }, { message, messageClass }) {
    commit("ADD_MESSAGE", { message, messageClass });
  },
  addInfoMessage({ commit }, msg) {
    commit("ADD_MESSAGE", { message: msg, messageClass: "info" });
  },
  addSuccessMessage({ commit }, msg) {
    commit("ADD_MESSAGE", { message: msg, messageClass: "success" });
  },
  addWarningMessage({ commit }, msg) {
    commit("ADD_MESSAGE", { message: msg, messageClass: "warning" });
  },
  addErrorMessage({ commit }, msg) {
    // "danger" is mapped to "error" in the component
    commit("ADD_MESSAGE", { message: msg, messageClass: "danger" });
  },
  clearMessage({ commit }) {
    commit("CLEAR_MESSAGE");
  },
  setMsgTimeout({ commit }, timeout) {
    commit("SET_TIMEOUT", { timeout });
  },
};

const getters = {
  messages: (state) => {
    return state.messageGroup;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
