import Vue from "vue";
import store from "../store/store.js";
import VueRouter from "vue-router";
import routes from "../routes";
import { Role } from "./_helper";
import Print from 'vue-print-nb'

function lazyLoad(view){
  return() => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}
function lazyLoadComponent(view){
  return() => import(/* webpackChunkName: "[request]" */ `@/components/${view}.vue`)
}


Vue.use(VueRouter);
Vue.use(Print);

const all_routes = routes.concat([
  {
    path: "/",
    name: "home",
    component: lazyLoad("Home"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager', 'Technician', 'Sales', 'Factory', 'Factory - Dashboard'] },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: lazyLoad("Dashboard"),
    meta: { requiresAuth:true, authorize: ['Factory - Dashboard'] },
  },
  {
    path: "/about",
    name: "About",
    component: lazyLoad("About"),
    meta: { authorize: [] },
  },
  {
    path: "/login",
    name: "Login",
    component: lazyLoadComponent("auth/Login"),
    meta: { authorize: [] },
  },
  {
    path: "/secure/reset/password",
    name: "ResetPassword",
    component: lazyLoad("secure/ResetPassword"),
    meta: { authorize: [Role.User] },
  }, 
  {
    path: "/secure/labels",
    name: "Labels",
    component: lazyLoad("Labels"),
    meta: { requiresAuth:true },
  },
  {
    path: "/secure/labelDetail/:id/",
    name: "LabelDetail",
    component: lazyLoad("LabelDetail"),
    meta: { requiresAuth:true },
  },
  {
    path: "/secure/purchase/receiving/:step?",
    name: "PurchaseReceiving",
    component: lazyLoad("secure/PurchaseReceiving"),
    meta: { requiresAuth:true },
  },
  {
    path: "/qr-dispatch/:uuid?",
    name: "QrDispatcher",
    component: lazyLoad("qr/QrDispatcher"),
    meta: {requiresAuth:true },
  },
  {
    path: "/qr-data",
    name: "QrData",
    component: lazyLoad("qr/QrData"),
    meta: {requiresAuth:true },
  },
  {
    path: "/secure/qrscan",
    name: "QrScan",
    component: lazyLoad("qr/QrScan"),
    meta: { requiresAuth:true },
  },
  {
    path: "/secure/qrcode/bind/:id/",
    name: "QrBind",
    component: lazyLoad("qr/QrBind"),
    meta: { requiresAuth:true },
  },
  {
    path: "/my-worksheets/:wsId?",
    name: "MyWorksheets",
    component: lazyLoad("process/myWorksheets"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager', 'Technician'] },
  },
  {
    path: "/my-ws-history",
    name: "MyWorksheetHistory",
    component: lazyLoadComponent("processWorksheet/MyWorksheetHistory"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager', 'Technician'] },
  },
  {
    path: "/open-order",
    name: "SalesOrder",
    component: lazyLoad("order/SalesOrder"),
    meta: { requiresAuth:true, authorize: ['Sales'] },
  },
  {
    path: "/flow-control",
    name: "FlowControl",
    component: lazyLoad("FlowControl"),
    meta: { requiresAuth:true, authorize: ['Sales'] },
  },
  {
    path: "/secure/inventory/available-units",
    name: "AvailableUnits",
    component: lazyLoad("inventory/AvailableUnits"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager', 'Factory', 'Technician'] },
  },
  {
    path: "/secure/inventory/trace",
    name: "InventoryTracing",
    component: lazyLoad("inventory/InventoryTracing"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager'] },
  },
  {
    path: '/purchased-battery',
    name: 'PurchasedBatteryReport',
    component: lazyLoadComponent("receiving/PurchasedBatteryReport"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager', 'Factory'] },
  },
  {
    path: '/process-conversion-report',
    name: 'ProcessConversionReport',
    component: lazyLoadComponent("process/ProcessConversionReport"),
    meta: { requiresAuth:true, authorize: ['Administrator', 'Manager'] },
  },
  {
    path: "/liveView",
    name: "LiveView",
    component: lazyLoadComponent("process/LiveView"),
    meta: { requiresAuth:true },
  },
  {
    path: "/tools/pdf-fill-form",
    name: "PdfFillForm",
    component: lazyLoadComponent("tools/PdfFormFiller"),
    meta: { requiresAuth:true },
  },
]);

const router = new VueRouter({
  mode: 'hash', //explicit mode even though its default
  routes: all_routes,
});


function isAuthorized(authorize){
  if(!authorize || authorize.length < 1){
    return true;
  }

  const userRoles = store.getters['auth/userRoles'];
  if(userRoles && userRoles.length > 0){
    return userRoles.some(r => authorize.includes(r));
  }
  else{
    store.dispatch("messages/addErrorMessage", 'Unauthorized Access', {root:true});
    return false;
  }

}

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.path.startsWith('/secure/') || record.meta.requiresAuth)) {
    const { authorize } = to.meta;
    if (store.getters['auth/isLoggedIn']) {
      //is logged in and has authorized permission
      if(isAuthorized(authorize)){
        next()
        return
      }
      else{
        //Is logged in, but not authorized
        store.dispatch("auth/processLogout", null, {root:true});
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    }
    //not logged in
    else{
      //Check if jwt token exists and attempt to automatically log in
      store.dispatch("auth/remoteJwtTokenCheck", null, {root:true})
        .then( () => {
          //check if user is authorized
          if(isAuthorized(authorize)){
            next()
          }
          else{
            //not authorized
            store.dispatch("auth/processLogout", null, {root:true});
            next({
              path: '/login',
              query: { redirect: to.fullPath }
            })
          }
        })
        .catch( () => {
          // console.log('fail jwt token check')
          store.dispatch("auth/processLogout", null, {root:true});
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
    }
  } else {
    //accessing non-secured page
    next()
  }
})

export default router;
